/* eslint-disable @typescript-eslint/ban-ts-comment,@typescript-eslint/no-unused-vars */
/* globals jQuery, wordpress_globals */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "../sass/theme.scss";
import $ from "jquery";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
// @ts-ignore
import svgxhr from "webpack-svgstore-plugin/src/helpers/svgxhr";
import AnimatedScroll from "./modules/AnimatedScroll";
import AffixedCallToAction from "./modules/AffixedCallToAction";
const bootstrap = {
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            this.helpers.initSvg();
            const modals = document.querySelectorAll(".modal");
            if (modals) {
                const Modals = yield import("./modules/Modals");
                Modals.default.init();
            }
            new AnimatedScroll();
            const navBar = document.querySelector("#page-navigation");
            if (navBar) {
                const navBarInit = () => __awaiter(this, void 0, void 0, function* () {
                    const NavBarNavigation = yield import("./modules/NavBarNavigation");
                    NavBarNavigation.default.create(navBar);
                    console.log("navBarInit");
                    document.removeEventListener("scroll", navBarInit);
                });
                const pageNavigationInit = () => __awaiter(this, void 0, void 0, function* () {
                    const PageNavigationModule = yield import("./modules/PageNavigation");
                    PageNavigationModule.default.create(navBar);
                    console.log("pageNavigationInit");
                    document.removeEventListener("mouseenter", pageNavigationInit);
                });
                navBar.addEventListener("mouseenter", pageNavigationInit);
                document.addEventListener("scroll", navBarInit);
            }
            ((_a = document.querySelectorAll(".copy-link")) !== null && _a !== void 0 ? _a : []).forEach((el) => {
                const copyLinkInit = () => __awaiter(this, void 0, void 0, function* () {
                    const CopyLink = yield import("./modules/CopyUrlToClipboard");
                    CopyLink.default.create(el);
                });
                copyLinkInit();
            });
            Array.from((_b = document.querySelectorAll("#cta")) !== null && _b !== void 0 ? _b : []).forEach((el) => new AffixedCallToAction(el));
            Array.from((_c = document.querySelectorAll(".simple-swiper")) !== null && _c !== void 0 ? _c : []).forEach((el) => {
                const simpleSwiperInit = () => __awaiter(this, void 0, void 0, function* () {
                    const SimpleSwiper = yield import("./modules/SimpleSwiper");
                    SimpleSwiper.default.create(el);
                });
                simpleSwiperInit();
            });
            Array.from((_d = document.querySelectorAll(".section--scroll-slider")) !== null && _d !== void 0 ? _d : []).forEach((el) => {
                const scrollSliderInit = () => __awaiter(this, void 0, void 0, function* () {
                    const ScrollSlider = yield import("./modules/ScrollSlider");
                    ScrollSlider.default.create(el);
                });
                scrollSliderInit();
            });
            // Todo: only load this when we scroll to the section
            Array.from((_e = document.querySelectorAll(".team-card")) !== null && _e !== void 0 ? _e : []).forEach((el) => {
                const button = el.querySelector(".collapsable-btn");
                const collapsableMenu = el.querySelector(".collapsable-menu");
                if (button && collapsableMenu) {
                    const collapsableInit = () => __awaiter(this, void 0, void 0, function* () {
                        const collapsable = yield import("./modules/Collapsable");
                        collapsable.default.create(collapsableMenu, button);
                    });
                    collapsableInit();
                }
            });
            Array.from((_f = document.querySelectorAll(".section--image-gallery, .block--image-gallery")) !== null && _f !== void 0 ? _f : []).forEach((el) => {
                const imageGalleryInit = () => __awaiter(this, void 0, void 0, function* () {
                    const ImageGallery = yield import("./modules/ImageGallery");
                    ImageGallery.default.create(el);
                });
                imageGalleryInit();
            });
            Array.from((_g = document.querySelectorAll(".truncated")) !== null && _g !== void 0 ? _g : []).forEach((el) => {
                const truncateInit = () => __awaiter(this, void 0, void 0, function* () {
                    const Truncate = yield import("./modules/Truncate");
                    Truncate.default.create(el);
                });
                truncateInit();
            });
            // Todo: only load this when we scroll to the section
            Array.from((_h = document.querySelectorAll(".gfield--has-description .gfield_description:not(.gfield_validation_message)")) !== null && _h !== void 0 ? _h : []).forEach((el) => {
                const infoButtonInit = () => __awaiter(this, void 0, void 0, function* () {
                    const InfoButton = yield import("./modules/InfoButton");
                    InfoButton.default.create(el);
                });
                infoButtonInit();
            });
            // Todo: only load this when we scroll to the section
            Array.from((_j = document.querySelectorAll(".accordion")) !== null && _j !== void 0 ? _j : [])
                .forEach((el) => {
                const accordionInit = () => __awaiter(this, void 0, void 0, function* () {
                    const Accordion = yield import("./modules/Accordion");
                    Accordion.default.create(el);
                });
                accordionInit();
            });
            Array.from((_k = document.querySelectorAll("video.header__video")) !== null && _k !== void 0 ? _k : []).forEach((el) => {
                const videoHeaderInit = () => __awaiter(this, void 0, void 0, function* () {
                    const VideoHeader = yield import("./modules/VideoHeader");
                    VideoHeader.default.create(el);
                });
                videoHeaderInit();
            });
            Array.from((_l = document.querySelectorAll(".section--vacancy-embed iframe")) !== null && _l !== void 0 ? _l : []).forEach((el) => {
                const vacancyEmbedInit = () => __awaiter(this, void 0, void 0, function* () {
                    const VacancyEmbed = yield import("./modules/VacancyEmbed");
                    VacancyEmbed.default.create(el);
                });
                vacancyEmbedInit();
            });
            // Allow user to interrupt auto-scrolling
            $(document).bind("scroll mousedown wheel keyup", (e) => {
                if ((e.which || e.type === "mousedown" || e.type === "mousewheel") && document.scrollingElement) {
                    $(document.scrollingElement).stop();
                }
            });
        });
    },
    helpers: {
        requireAll(r) {
            r.keys().forEach(r);
        },
        initSvg() {
            svgxhr({
                filename: "./wp-content/themes/class/dist/sprite.svg", // URL to fetch
            });
        },
    },
};
document.addEventListener("DOMContentLoaded", () => {
    bootstrap.init();
});
// SVG Sprite Loader
bootstrap.helpers.requireAll(require.context("../images/icons/", true, /\.svg$/));
